<template>
  <div id="subscriptions">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />
    </v-container>
    <v-container
      fluid
      class="elevation-2 main-container"
    >
      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Batch Name"
            placeholder="Batch Name"
            outlined
            clearable
            dense
            v-model="filter.batchName"
            @keydown.enter="generateSubReport"
          />
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            outlined
            clearable
            dense
            label="School"
            :items="schools"
            item-text="rec"
            item-value="code"
            v-model="filter.subscriber"
            prepend-inner-icon="mdi-school"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            clearable
            dense
            label="Supplier"
            :items="suppliers"
            item-text="name"
            item-value="supplierId"
            v-model="filter.supplier"
            prepend-inner-icon="mdi-account-tie"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            clearable
            dense
            label="Status"
            :items="['Activated', 'Expired']"
            v-model="filter.status"
            prepend-inner-icon="mdi-list-status"
          />
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :close-on-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :position-x="menuPositionX"
            :position-y="menuPositionY"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDateRange()"
                label="Expiry Date Range"
                prepend-inner-icon="mdi-calendar"
                outlined
                clearable
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                ref="dateInput"
                @click:clear="clearDates"
              />
            </template>
            <v-date-picker
              v-model="filter.dateRange"
              range
              @change="handleDateChange"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="3"
          class="offset-3"
        >
          <v-btn
            block
            large
            color="primary"
            buttonText="Search"
            :disabled="disableGenerateBtn"
            @click="generateSubReport"
          >
            <v-icon class="mr-1">mdi-magnify</v-icon>
            Search
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            large
            color="primary"
            :disabled="!subscriptionList.length"
            buttonText="Download"
            @click="saveSubscriptionsCsv"
          >
            <v-icon class="mr-1">mdi-download</v-icon>
            Download
          </v-btn>
        </v-col>
      </v-row>
      <Alert
        :showAlert="lastEvaluatedKey"
        v-if="lastEvaluatedKey"
        type="info"
        allowDismiss
        absolute
        text="Your search returned 500 or more rows. Consider adding more filters to narrow down results"
        :inPage="true"
        :backgroundColour="true"
      />
      <AdsDataTable
        :headers="headers"
        :items="subscriptionList"
        :footer-props="{
          'items-per-page-options': itemPerPageOptions,
        }"
        :items-per-page="20"
        search-label="Find subscriptions"
        sort-desc
        @click:row="openSubscription"
      >
        <template v-slot:item.orderNumber="{ item }">
          <div class="hyperLink">
            {{ item.isOrderNoTypeOther ? 'Other' : item.orderNumber }}
          </div>
        </template>
        <template v-slot:item.services="{ item }">
          {{ getFirstCharacters(item.services, 50) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusChipColor(item.status).background"
            :text-color="getStatusChipColor(item.status).color"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          {{ item.lastModified }}
        </template>
      </AdsDataTable>
    </v-container>
  </div>
</template>
<script>
import { AdsDataTable, Alert } from '@nswdoe/doe-ui-core'
import csvDownload from 'json-to-csv-export'
import dayjs from 'dayjs'
import Header from '@/views/Subscriptions/Header/Header'
import { isEmpty } from 'lodash'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const itemPerPageOptions = [20, 30, 50, -1]
const headers = [
  { text: 'Batch Name', value: 'batchName' },
  { text: 'Order Number/Other', value: 'orderNumber' },
  { text: 'Supplier', value: 'supplierName' },
  { text: 'Product', value: 'productName' },
  { text: 'Finance Exclusive', value: 'isFinancial', align: 'center' },
  { text: 'Subcategory', value: 'services' },
  { text: 'Subscriber', value: 'subscriber' },
  { text: 'School code', value: 'schoolCode' },
  { text: 'Start Date', value: 'startDate' },
  { text: 'Expiry Date', value: 'endDate' },
  { text: 'Status', value: 'status' },
  { text: 'Actioned By', value: 'updatedBy' },
  { text: 'Last Modified Date', value: 'lastModified' },
]

export default {
  name: 'SubscriptionsListing',
  title: 'Subscriptions - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
    Alert,
    Header,
  },
  computed: {
    schools() {
      return this.$store.state.moduleSchools.schools || []
    },
    suppliers() {
      return this.$store.state.moduleSuppliers.suppliers || []
    },
    disableCsvBtn() {
      return !this.subscriptionList.length
    },
    disableGenerateBtn() {
      return !this.filter.supplier && !this.filter.subscriber
    },
    filter() {
      return this.$store.state.moduleSubscriptions.filter
    },
  },
  async created() {
    if (
      !this.$store.state.moduleSuppliers.suppliers ||
      this.$store.state.moduleSuppliers.suppliers.length === 0
    ) {
      await this.$store.dispatch('moduleSuppliers/fetchSuppliers')
    }
    if (
      !this.$store.state.moduleSchools.schools ||
      this.$store.state.moduleSchools.schools.length === 0
    ) {
      await this.$store.dispatch('moduleSchools/fetchSchools')
    }

    if (this.$store.state.moduleSubscriptions.subscriptions.length > 0) {
      this.formatData()
    }
  },
  data() {
    return {
      headers,
      menu: false,
      menuPositionX: 0,
      menuPositionY: 0,
      itemPerPageOptions,
      fetchingSubList: false,
      lastEvaluatedKey: false,
      subscriptionList: [],
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateMenuPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMenuPosition)
  },
  watch: {
    menu(val) {
      if (val) {
        this.$nextTick(this.updateMenuPosition)
      }
    },
  },
  methods: {
    clearDates() {
      this.filter.dateRange = []
      this.menu = false
    },
    formatDateRange() {
      if (!this.filter.dateRange || isEmpty(this.filter.dateRange)) return null
      return (
        this.filter.dateRange
          .map((date) => (date ? dayjs(date).format('DD/MM/YYYY') : ''))
          .join(' - ') || null
      )
    },
    updateMenuPosition() {
      if (!this.$refs.dateInput) return

      const inputRect = this.$refs.dateInput.$el.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      // Determine X position
      if (inputRect.left + 290 > windowWidth) {
        // Align to right edge if it would overflow on the right
        this.menuPositionX = windowWidth - 290
      } else {
        // Otherwise, align with the input field
        this.menuPositionX = inputRect.left
      }

      // Determine Y position
      if (inputRect.bottom + 320 > windowHeight) {
        // Position above the input if it would overflow at the bottom
        this.menuPositionY = inputRect.top - 320
      } else {
        // Otherwise, position below the input
        this.menuPositionY = inputRect.bottom
      }
    },
    handleDateChange(dates) {
      if (dates.length === 2) {
        // Ensure dates are in chronological order
        const [date1, date2] = dates.map((d) => new Date(d))
        if (date1 > date2) {
          this.filter.dateRange = [dates[1], dates[0]]
        } else {
          this.filter.dateRange = dates
        }
        this.$nextTick(() => {
          this.menu = false
        })
      } else {
        this.filter.dateRange = dates
      }
    },
    getFirstCharacters(str, length) {
      return str.length > length ? str.substring(0, length) + ' ...' : str
    },
    formatData() {
      this.subscriptionList = this.$store.state.moduleSubscriptions.subscriptions.map((item) => {
        return {
          orderNumber:
            item.justification.value + (item?.justification?.type === 'other' ? 'Other' : ''),
          isOrderNoTypeOther: item?.justification?.type === 'other' ? true : false,
          supplierName: item.supplierName,
          productName: item.productName,
          services: item.services.join(', '),
          subscriber: this.schools.find((school) => school.code === item.schoolCode)?.name,
          schoolCode: item.schoolCode,
          startDate: item.startDate,
          endDate: item.endDate,
          status: item.status,
          isFinancial: item.isFinancial ? 'Yes' : 'No',
          supplierId: item.supplierId,
          subscriptionId: item.subscriptionId,
          updatedBy: item.updatedBy,
          updatedAt: item.updatedAt,
          lastModified: dayjs(item.updatedAt).utc(true).format('DD/MM/YYYY HH:mm:ss'),
          batchName: item.batchName || '',
          batchId: item.batchId || '',
        }
      })
      this.lastEvaluatedKey = this.$store.state.moduleSubscriptions.lastEvaluatedKey ? true : false
    },
    generateSubReport() {
      const { status, supplier, subscriber, dateRange, batchName } = this.filter
      if (!supplier && !subscriber) return
      const fromDate = dateRange[0] ? dayjs(dateRange[0]).utc(true).toISOString() : undefined
      let toDate = dateRange[1] ? dayjs(dateRange[1]).utc(true).toISOString() : undefined

      if (fromDate && !toDate) {
        toDate = dayjs(dateRange[0]).endOf('day').utc(true).toISOString()
      }

      let payload = {
        batchName,
        status,
        fromDate,
        toDate,
        supplierId: supplier,
        schoolCode: subscriber,
      }

      this.$store.dispatch('moduleSubscriptions/fetchSubscriptions', payload).finally(() => {
        this.formatData()
      })
    },
    saveSubscriptionsCsv() {
      const data = this.subscriptionList.map((values) => {
        const {
          batchName,
          orderNumber,
          isOrderNoTypeOther,
          supplierName,
          productName,
          isFinancial,
          services,
          subscriber,
          schoolCode,
          startDate,
          endDate,
          status,
          updatedBy,
          lastModified,
        } = values
        return {
          batchName,
          orderNumber: isOrderNoTypeOther ? orderNumber.slice(0, -5) : orderNumber,
          supplierName,
          productName,
          isFinancial,
          services,
          subscriber,
          schoolCode,
          startDate,
          endDate,
          status,
          updatedBy,
          lastModified,
        }
      })

      csvDownload({
        data,
        filename: 'Subscriptions',
        delimiter: ',',
        headers: headers.map((header) => header.text),
      })
    },

    openSubscription(item, table, event) {
      if (event.target.classList.contains('hyperLink') || event.target.closest('.hyperLink')) {
        this.$router.push(
          `/serviceregistry/subscriptions/view/${item.subscriptionId}?supplierId=${item.supplierId}`
        )
      }
    },
    getStatusChipColor(status) {
      switch (status) {
        case 'Activated':
          return { background: 'success', color: 'white' }
        case 'Expired':
          return { background: 'error', color: 'white' }
        default:
          return { background: 'grey', color: 'white' }
      }
    },
  },
}
</script>

<style scoped>
.hyperLink {
  color: #0351a5;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.hyperLink:hover {
  text-decoration: underline;
}
</style>
