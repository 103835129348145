<template>
  <div>
    <Banner
      :heading="banner.heading"
      :subtitle="banner.subtitle"
      :icon="banner.icon"
      iconSize="xxx-large"
    />
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core'

export default {
  name: 'Header',
  components: {
    Banner,
  },
  data() {
    return {
      banner: {
        heading: 'Subscriptions',
        subtitle: 'Listing Page',
        icon: 'mdi-text-box-multiple-outline',
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
